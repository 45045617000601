import { cx } from '@scalingworks/refine-vechaiui';
import React from 'react';

interface FieldProps {
  title: string;
  text?: string | React.ReactNode;
}

export const Field = ({ title, text, ...props }: FieldProps) => {
  return (
    <div className="mb-4">
      <div className={cx('font-semibold text-base')}>{title}</div>
      <span className={cx('font-light break-words')}>{text || '-'}</span>
    </div>
  );
};
