import { AdminPermission } from 'api';

export const RESOURCE_NAME = 'admin-roles';
export const RESOURCE_FIELDS = ['id', 'code', 'name', 'description', 'permissions', 'createdAt', 'updatedAt'] as const;
export const NESTED_RESOURCE_FIELDS = { role: ['id', 'code', 'name'] } as const;
export const RESOURCE_KEYS = [...RESOURCE_FIELDS, 'role'] as const;
export const ROLES_RESOURCE_NAME = 'admin-roles';
export const ROLES_RESOURCE_FIELDS = ['id', 'code', 'name', 'description'] as const;
export const PERMISSIONS_RESOUCE_NAME = 'admin-permissions';
export const PERMISSIONS_RESOUCE_FIELDS = ['id', 'code', 'name', 'resource'] as const;
export type AdminPermissionFields = Pick<AdminPermission, typeof PERMISSIONS_RESOUCE_FIELDS[number]>;
