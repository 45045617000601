import { useLogin, useTranslate } from '@pankod/refine-core';
import './style.less';
import { Trans } from 'react-i18next';
import { Button, Card, cx, Input, FormControl, FormLabel, Checkbox, Layout } from '@scalingworks/refine-vechaiui';
import { useForm } from '@pankod/refine-react-hook-form';

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: 'admin@demo.com',
      password: 'abcd1234',
      remember: false,
    },
  });
  const t = useTranslate();

  const { mutate: login, isLoading } = useLogin<ILoginForm>();

  const onSubmit = (values: any) => {
    login(values);
  };

  const CardTitle = (
    <h3 className="text-2xl">
      <Trans
        i18nKey="pages.login.message"
        defaults="<0>Sign in</0> your account"
        components={[<span key="0" className="text-primary-600" />]}
      />
    </h3>
  );

  return (
    <Layout Sider={() => null}>
      <div className={cx('flex justify-center items-center h-screen')}>
        <div className={cx('w-11/12')}>
          <div style={{ maxWidth: '408px', margin: 'auto' }}>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl id="username" className="mb-4">
                  <FormLabel>{t('pages.login.email', 'Username')}</FormLabel>
                  <Input
                    {...register('username', { required: true })}
                    invalid={!!errors?.username || undefined}
                    placeholder={t('users.fields.username', 'Username')}
                    size="lg"
                  />
                </FormControl>

                <FormControl id="password" className="mb-4">
                  <FormLabel>{t('pages.login.password', 'Password')}</FormLabel>
                  <Input
                    {...register('password', { required: true })}
                    invalid={!!errors?.password || undefined}
                    placeholder="●●●●●●●●"
                    type="password"
                    size="lg"
                  />
                </FormControl>

                <FormControl>
                  <Checkbox {...register('remember')}>Remember</Checkbox>
                </FormControl>

                <Button
                  loading={isLoading}
                  type="submit"
                  variant="solid"
                  size="lg"
                  color="primary"
                  className="w-full mt-4"
                >
                  {t('pages.login.signin', 'Sign in')}
                </Button>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};
