export const RESOURCE_NAME = 'admin-users';
export const RESOURCE_FIELDS = [
  'id',
  'name',
  'username',
  'email',
  'status',
  'roleId',
  'createdAt',
  'updatedAt',
] as const;
export const NESTED_RESOURCE_FIELDS = { role: ['id', 'code', 'name'] } as const;
export const RESOURCE_KEYS = [...RESOURCE_FIELDS, 'role'] as const;
export const ROLES_RESOURCE_NAME = 'admin-roles';
export const ROLES_RESOURCE_FIELDS = ['id', 'code', 'name', 'description'] as const;
