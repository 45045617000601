import { CrudFilters, CrudSorting, DataProvider, MetaDataQuery } from '@pankod/refine-core';
import { GraphQLClient } from 'graphql-request';
import * as gql from 'gql-query-builder';
import pluralize from 'pluralize';
import camelCase from 'camelcase';
import { pascalCase } from 'change-case';
import { QueryMode } from 'api';

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const sortQuery = sort.map((i) => {
      return {
        [i.field]: i.order,
      };
    });

    return sortQuery;
  }

  return [];
};

const generateFilter = (filters?: CrudFilters) => {
  if (filters && filters.length > 0) {
    const queryFilters = (filters as any[]).map(({ field, operator, value }) => {
      // @ts-ignore
      let stringFilter = primaStringFilterMappings[`${operator}`];
      if (stringFilter) {
        let filterObject = {
          [field]: {
            [stringFilter as string]: value,
          },
        };

        if (stringFilter === 'contains') {
          filterObject[field]['mode'] = QueryMode.Insensitive;
        }

        return filterObject;
      }
    });

    return queryFilters;
  }

  return [];
};

const overrideInputType = (type: string) => {
  const inputTypesMapping: { [key: string]: string } = {
    AdminRoleCreateInput: 'AdminRoleCreateInputV2',
    AdminRoleUpdateInput: 'AdminRoleUpdateInputV2',
  };
  return inputTypesMapping[type] ?? type;
};

const prismaInputType = {
  paginate: (name: string) => pascalCase(`${name}WhereUniqueInput`),
  findUnique: (name: string) => pascalCase(`${name}WhereUniqueInput`),
  findFirst: (name: string) => pascalCase(`${name}WhereInput`),
  findMany: (name: string) => pascalCase(`${name}WhereInput`),
  create: (name: string) => overrideInputType(pascalCase(`${name}CreateInput`)),
  createMany: (name: string) => pascalCase(`${name}CreateManyInput`),
  update: (name: string) => overrideInputType(pascalCase(`${name}UpdateInput`)),
  updateMany: (name: string) => pascalCase(`${name}UpdateManyInput`),
};

const prismaVariableOperation = {
  update: (variables: any) => {
    Object.keys(variables).map((key) => {
      if (
        typeof variables[key] === 'object' &&
        ('connect' in variables[key] ||
          'disconnect' in variables[key] ||
          'create' in variables[key] ||
          'update' in variables[key] ||
          'upsert' in variables[key] ||
          'delete' in variables[key] ||
          'connectOrCreate' in variables[key])
      ) {
        return;
      }
      if (key === 'id' || key === 'permissions') {
        return;
      }
      variables[key] = { set: variables[key] };
    });
    return variables;
  },
};

const primaStringFilterMappings = {
  eq: 'equals',
  lt: 'lt',
  gt: 'gt',
  lte: 'lte',
  gte: 'gte',
  in: 'in',
  nin: 'notIn',
  contains: 'contains',
  ne: false,
  ncontains: false,
  containss: false,
  ncontainss: false,
  null: false,
};

const operationParse = (resource: string, metaData: MetaDataQuery | undefined) => {
  const singularResource = pluralize.singular(resource);
  return {
    operation: metaData?.operation ?? camelCase(singularResource),
    resource: singularResource,
  };
};

interface PrismaDataProvider extends DataProvider {}

const dataProvider = (client: GraphQLClient): PrismaDataProvider => {
  return {
    getList: async ({ resource, pagination, sort, filters, metaData }) => {
      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 10;
      const orderBy = generateSort(sort);
      const filterBy = generateFilter(filters);
      const operation = metaData?.operation ?? camelCase(`${resource}`);
      const singularResource = pluralize.singular(resource);

      const { query, variables } = gql.query({
        operation,
        variables: {
          ...(orderBy.length > 0 && {
            page: current,
            perPage: pageSize,
            orderBy: {
              value: orderBy,
              type: `[${pascalCase(singularResource)}OrderByWithRelationInput!]`,
            },
          }),
          ...(filterBy.length > 0 && {
            where: {
              value: {
                OR: filterBy,
              },
              type: prismaInputType.findMany(singularResource),
            },
          }),
        },
        fields: [{ items: metaData?.fields }, { metadata: ['currentPage', 'totalPages', 'totalCount', 'perPage'] }],
      });

      const response = await client.request(query, variables, {});

      return {
        data: response[operation].items,
        total: response[operation].metadata?.totalCount,
        metadata: response[operation].metadata,
      };
    },

    getMany: async ({ resource, ids, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(resource);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id: { in: ids } },
            type: prismaInputType.findMany(singularResource),
          },
        },
        fields: metaData?.fields,
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    getOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(`${singularResource}`);

      const { query, variables } = gql.query({
        operation,
        variables: {
          id: {
            name: "id",
            type: "ID",
            value: id,
          },
        },
        fields: metaData?.fields,
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    create: async ({ resource, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(`create_one_${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          data: {
            value: variables,
            type: prismaInputType.create(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await client.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },

    createMany: async ({ resource, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(`create_many_${singularResource}`);

      const response = await Promise.all(
        variables.map(async (param) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { data: param },
                type: prismaInputType.createMany(singularResource),
              },
            },
            fields: metaData?.fields ?? ['id'],
          });
          const result = await client.request(query, gqlVariables);

          return result[operation];
        })
      );
      return {
        data: response,
      };
    },

    update: async ({ resource, id, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(`updateOne_${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
          data: {
            value: variables,
            type: prismaInputType.update(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await client.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },

    updateMany: async ({ resource, ids, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const camelUpdateName = camelCase(`update-${singularResource}`);

      const operation = metaData?.operation ?? camelUpdateName;

      const response = await Promise.all(
        ids.map(async (id) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { where: { id }, data: variables },
                type: prismaInputType.updateMany(singularResource),
              },
            },
            fields: metaData?.fields ?? ['id'],
          });
          const result = await client.request(query, gqlVariables);

          return result[operation];
        })
      );
      return {
        data: response,
      };
    },

    deleteOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(`delete_${singularResource}`);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    deleteMany: async ({ resource, ids, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const camelDeleteName = camelCase(`delete-${singularResource}`);

      const operation = metaData?.operation ?? camelDeleteName;

      const response = await Promise.all(
        ids.map(async (id) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { where: { id } },
                type: prismaInputType.findFirst(singularResource),
              },
            },
            fields: metaData?.fields ?? ['id'],
          });
          const result = await client.request(query, gqlVariables);

          return result[operation][singularResource];
        })
      );
      return {
        data: response,
      };
    },

    getApiUrl: () => {
      throw Error('Not implemented on refine-graphql data provider.');
    },

    custom: async ({ url, method, headers, metaData }) => {
      let gqlClient = client;

      if (url) {
        gqlClient = new GraphQLClient(url, { headers });
      }

      if (metaData) {
        if (metaData.operation) {
          if (method === 'get') {
            const { query, variables } = gql.query({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await gqlClient.request(query, variables);

            return {
              data: response[metaData.operation],
            };
          } else {
            const { query, variables } = gql.mutation({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await gqlClient.request(query, variables);

            return {
              data: response[metaData.operation],
            };
          }
        } else {
          throw Error('GraphQL operation name required.');
        }
      } else {
        throw Error('GraphQL need to operation, fields and variables values in metaData object.');
      }
    },
  };
};

export default dataProvider;
