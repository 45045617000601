import { Card } from '@scalingworks/refine-vechaiui';
import { useTranslation } from 'react-i18next';

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 gap-10 grid-rows-2">
      <div>
        <Card
          bodyStyle={{
            height: 550,
            padding: 0,
          }}
          title={<strong>{t('dashboard.deliveryMap.title')}</strong>}
        ></Card>
      </div>
      <div>
        <Card
          bodyStyle={{
            height: 550,
            overflowY: 'scroll',
          }}
          title={<strong>{t('dashboard.timeline.title')}</strong>}
        ></Card>
      </div>
      <div>
        <Card title={<strong>{t('dashboard.recentOrders.title')}</strong>}></Card>
      </div>
      <div>
        <Card title={<strong>{t('dashboard.trendingMenus.title')}</strong>}></Card>
      </div>
    </div>
  );
};
