import { Refine } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import dataProvider from './dataProvider';
import { GraphQLClient } from 'graphql-request';
import { authProvider, authTokenProvider } from 'authProvider';
import { Title, Header, Sider, Footer, OffLayoutArea } from 'components/layout';
import { useTranslation } from 'react-i18next';
import { LoginPage } from 'pages/login';
import { ForgetPasswordPage } from 'pages/forget-password';
import { DashboardPage } from 'pages/dashboard';
import { ThemeProvider, Layout, ErrorComponent, notificationProvider } from '@scalingworks/refine-vechaiui';
import { AiOutlineUser } from 'react-icons/ai';

import './styles/main.css';
import '@scalingworks/refine-vechaiui/dist/main.min.css';
import '@scalingworks/refine-vechaiui/dist/styles.min.css';
import { UserListing } from 'pages/admin-users';
import { AdminRoleList } from 'pages/admin-roles/list';
import { ToastContainer } from 'react-toastify';

const API_URL = `${process.env.REACT_APP_API_BASE}/graphql`;
export const client = new GraphQLClient(API_URL, {
  headers: {
    authorization: authTokenProvider()?.accessToken ? `Bearer ${authTokenProvider()!.accessToken}` : '',
  },
});

export const gqlDataProvider = dataProvider(client);

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ThemeProvider>
      <Refine
        disableTelemetry={true}
        dataProvider={gqlDataProvider}
        authProvider={authProvider}
        Title={Title}
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        catchAll={<ErrorComponent />}
        OffLayoutArea={OffLayoutArea}
        i18nProvider={i18nProvider}
        DashboardPage={DashboardPage}
        LoginPage={LoginPage} // FIXME: weird error web console, default have the same error
        notificationProvider={notificationProvider}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <ForgetPasswordPage />,
              path: '/forget-password',
            },
            {
              element: <UserListing />,
              path: '/admin-users/:id',
              layout: true,
            },
            {
              element: <UserListing />,
              path: '/admin-users/:id/:formaction',
              layout: true,
            },
            {
              element: <AdminRoleList />,
              path: '/admin-roles/:id',
              layout: true,
            },
            {
              element: <AdminRoleList />,
              path: '/admin-roles/:id/:formaction',
              layout: true,
            },
          ],
        }}
        resources={[
          {
            name: 'User Management',
            icon: <AiOutlineUser />,
            options: {
              label: 'User Management',
            },
          },
          {
            name: 'admin-users',
            list: UserListing,
            parentName: 'User Management',
            options: { route: 'admin-users', label: 'Admin Users' },
          },
          {
            name: 'admin-roles',
            list: AdminRoleList,
            parentName: 'User Management',
            options: { route: 'admin-roles', label: 'Admin Roles' },
          },
        ]}
        reactQueryClientConfig={{
          defaultOptions: {
            queries: {
              retry: 1,
            },
          },
        }}
      ></Refine>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
