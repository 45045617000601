import { Skeleton } from '@scalingworks/refine-vechaiui';

const LoadingSkeleton = () => {
  return (
    <div>
      <Skeleton title={{ className: 'mb-2' }} active loading className="mb-6" />
      <Skeleton title={{ className: 'mb-2' }} active loading className="mb-6" />
      <Skeleton title={{ className: 'mb-2' }} active loading className="mb-6" />
      <Skeleton title={{ className: 'mb-2' }} active loading className="mb-6" />
      <Skeleton title={{ className: 'mb-2' }} active loading className="mb-6" />
    </div>
  );
};

export default LoadingSkeleton;
