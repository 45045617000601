type SidebarOptions = {
  collapsed: boolean;
};

export const getSidebarState = (): SidebarOptions => {
  const storageValue = localStorage.getItem('sidebarState');
  if (typeof storageValue !== 'string') {
    return { collapsed: false };
  }
  return JSON.parse(storageValue);
};

export const setSidebarState = (options: SidebarOptions): void => {
  localStorage.setItem('sidebarState', JSON.stringify(options));
};
