import { useTranslate, HttpError, CreateResponse, UpdateResponse, useList } from '@pankod/refine-core';
import { Controller, FieldValues, useForm } from '@pankod/refine-react-hook-form';
import { Create, FormControl, FormLabel, Input, ListButton, Checkbox, Card } from '@scalingworks/refine-vechaiui';
import {
  AdminPermissionFields,
  PERMISSIONS_RESOUCE_FIELDS,
  PERMISSIONS_RESOUCE_NAME,
  RESOURCE_FIELDS,
  RESOURCE_NAME,
} from '../constant';
import { LoadingSkeleton } from '../../../components/shared';
import { AdminRoleFields } from '../list';

type OnMutationSuccess = (
  data: CreateResponse<AdminRoleFields> | UpdateResponse<AdminRoleFields>,
  variables: FieldValues,
  context: any
) => void | undefined;

type OnMutationError = ((error: HttpError, variables: FieldValues, context: any) => void) | undefined;

export const UserCreate: React.FC<{
  hideListButton?: boolean;
  onMutationSuccess?: OnMutationSuccess;
  onMutationError?: OnMutationError;
  onBack?: (e?: any) => void;
}> = (props) => {
  const t = useTranslate();
  const { onBack, onMutationSuccess, onMutationError } = props;

  const { data: adminPermissionsResponse, isLoading } = useList<AdminPermissionFields>({
    resource: PERMISSIONS_RESOUCE_NAME,
    metaData: {
      fields: [...PERMISSIONS_RESOUCE_FIELDS],
    },
  });

  const adminPermissions = adminPermissionsResponse?.data || [];

  const {
    handleSubmit,
    saveButtonProps,
    register,
    control,
    formState: { errors },
    refineCore: { queryResult, onFinish },
  } = useForm<AdminRoleFields, HttpError>({
    refineCoreProps: {
      resource: RESOURCE_NAME,
      metaData: {
        fields: [...RESOURCE_FIELDS],
      },
      onMutationSuccess,
      onMutationError,
    },
  });

  const loadingForm = isLoading;

  const onSubmit = (e: any) => {
    handleSubmit((fieldValues) => {
      return onFinish({ ...fieldValues, code: fieldValues?.name?.toLowerCase() });
    })(e);
  };

  return (
    <Create
      hideBreadcrumbs
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        ...saveButtonProps,
        type: 'submit',
        onClick: onSubmit,
      }}
      pageHeaderProps={{
        extra: !props?.hideListButton && <ListButton />,
        onBack,
      }}
    >
      {loadingForm ? (
        <LoadingSkeleton />
      ) : (
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-12">
            <div className="col-span-full">
              <div className="text-lg mb-4">Role Information</div>
              <FormControl id="name" className="mb-4">
                <FormLabel>{t('user-roles.fields.name')}</FormLabel>
                <Input
                  {...register('name', { required: true })}
                  invalid={!!errors.name}
                  placeholder={t('user-roles.fields.name')}
                  size="lg"
                />
              </FormControl>
              <FormControl id="description" className="mb-4">
                <FormLabel>{t('user-roles.fields.description')}</FormLabel>
                <Input
                  {...register('description', { required: true })}
                  invalid={!!errors.description}
                  type="email"
                  placeholder={t('user-roles.fields.description')}
                  size="lg"
                />
              </FormControl>
              <div className="text-lg mb-4">Permissions</div>
              <Controller
                name="permissions"
                defaultValue={[]}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <div>
                      <FormControl id="permissions" className="mb-4 md:flex md:space-x-3">
                        <div className="w-4/12 mr-2">Administrator Access</div>
                        <Checkbox.Group
                          name="permissions"
                          value={value}
                          onChange={(newVal) => {
                            newVal.includes('super_admin')
                              ? onChange(adminPermissions.map((p) => p.code))
                              : onChange([]);
                          }}
                          defaultValue={[]}
                          className="md:flex md:flex-row md:space-x-2 w-8/12"
                        >
                          {adminPermissions
                            ?.filter((i) => i.resource === '*')
                            .map((permission, index) => (
                              <Checkbox value={permission.code} key={`${permission.id}${index}`}>
                                Select all
                              </Checkbox>
                            ))}
                        </Checkbox.Group>
                      </FormControl>
                      <FormControl id="permissions" className="mb-4 md:flex md:space-x-3">
                        <div className="w-4/12 mr-2">User</div>
                        <Checkbox.Group
                          name="permissions"
                          value={value}
                          onChange={(newVal) => {
                            adminPermissions?.filter((i) => i.resource === 'user').every((p) => newVal.includes(p.code))
                              ? onChange(adminPermissions.map((p) => p.code))
                              : onChange(newVal);
                          }}
                          defaultValue={[]}
                          className="md:flex md:flex-row md:space-x-2 w-8/12"
                        >
                          {adminPermissions
                            ?.filter((i) => i.resource === 'user')
                            .map((permission, index) => (
                              <Checkbox
                                disabled={value.includes('super_admin')}
                                checked={value.includes('super_admin')}
                                value={permission.code}
                                key={`${permission.id}${index}`}
                              >
                                {permission.name}
                              </Checkbox>
                            ))}
                        </Checkbox.Group>
                      </FormControl>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </form>
      )}
    </Create>
  );
};
