import { useTranslate, useShow } from '@pankod/refine-core';
import { ListButton, Space, Show, EditButton, DateField, Tag } from '@scalingworks/refine-vechaiui';
import { Field, LoadingSkeleton } from 'components/shared';
import { RESOURCE_NAME, RESOURCE_FIELDS } from '../constant';
import { AdminRoleFields } from '../list';

export const ShowContent: React.FC<{
  id: string;
  hideListButton?: boolean;
  hideEditButton?: boolean;
  onBack?: (e?: any) => void;
}> = (props) => {
  const t = useTranslate();
  const { onBack, hideEditButton, hideListButton, id } = props;

  const { queryResult } = useShow<AdminRoleFields>({
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    id,
  });
  const role = queryResult?.data?.data;

  return (
    <>
      <Show
        hideBreadcrumbs
        pageHeaderProps={{
          extra: (
            <div className="space-x-2">
              {!hideListButton && <ListButton />}
              {!hideEditButton && <EditButton />}
            </div>
          ),
          onBack,
        }}
      >
        {queryResult?.isLoading ? (
          <LoadingSkeleton />
        ) : (
          <Space size={20} direction="vertical" style={{ width: '100%' }}>
            <div className="grid grid-cols-12">
              <div className="col-span-full md:col-span-6">
                <Field title={t('user-roles.fields.id')} text={role?.id} />
                <Field title={t('user-roles.fields.name')} text={role?.name} />
                <Field title={t('user-roles.fields.description')} text={role?.description} />
                <Field
                  title={t('user-roles.fields.createdAt')}
                  text={<DateField value={role?.createdAt} format="LLL" />}
                />
                <Field
                  title={t('user-roles.fields.updatedAt')}
                  text={<DateField value={role?.updatedAt} format="LLL" />}
                />
                <Field
                  title={t('user-roles.fields.permissions')}
                  text={role?.permissions?.map((perm) => (
                    <Tag key={perm} className="capitalize mr-2 mt-2" variant="solid" color="primary">
                      {perm}
                    </Tag>
                  ))}
                />
              </div>
            </div>
          </Space>
        )}
      </Show>
    </>
  );
};
