import { useGetLocale, useSetLocale, useGetIdentity } from '@pankod/refine-core';
import { useTranslation } from 'react-i18next';
import { cx, Space, ThemeSwitcher, ThemeToggle, Avatar, Select } from '@scalingworks/refine-vechaiui';

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity();

  const currentLocale = locale();

  const menu = (
    <Select defaultValue={currentLocale} onChange={(e) => changeLanguage(e.target.value)}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <option key={lang} value={lang}>
          {lang === 'en' ? 'English' : lang.toUpperCase()}
        </option>
      ))}
    </Select>
  );

  return (
    <header
      className="bg-white dark:bg-base space-x-2"
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 24px',
        height: '64px',
      }}
    >
      <div className={cx('w-32')}>
        {menu}
      </div>
      <div className={cx('flex flex-row space-x-2')}>
        <ThemeSwitcher inNavbar />
        <ThemeToggle />
      </div>
      <Space style={{ marginLeft: '8px' }}>
        {user?.name && <div className="text-ellipsis font-bold">{user.name}</div>}
        {user?.avatar && <Avatar src={user?.avatar} name={user?.name} />}
      </Space>
    </header>
  );
};
