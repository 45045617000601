import { useTranslate, HttpError, CreateResponse, UpdateResponse, useList } from '@pankod/refine-core';
import { Edit, ListButton, FormControl, FormLabel, Input, Card, Checkbox } from '@scalingworks/refine-vechaiui';
import { Controller, FieldValues, useForm } from '@pankod/refine-react-hook-form';
import {
  AdminPermissionFields,
  PERMISSIONS_RESOUCE_FIELDS,
  PERMISSIONS_RESOUCE_NAME,
  RESOURCE_FIELDS,
  RESOURCE_NAME,
} from '../constant';
import { LoadingSkeleton } from '../../../components/shared';
import { AdminRoleFields } from '../list';

type OnMutationSuccess =
  | ((
      data: CreateResponse<AdminRoleFields> | UpdateResponse<AdminRoleFields>,
      variables: FieldValues,
      context: any
    ) => void)
  | undefined;

type OnMutationError = ((error: HttpError, variables: FieldValues, context: any) => void) | undefined;

export const UserEdit: React.FC<{
  id: string;
  hideListButton?: boolean;
  onMutationSuccess?: OnMutationSuccess;
  onMutationError?: OnMutationError;
  onBack?: (e?: any) => void;
}> = (props) => {
  const t = useTranslate();
  const { onBack, hideListButton, id, onMutationError, onMutationSuccess } = props;

  const { data: adminPermissionsResponse, isLoading } = useList<AdminPermissionFields>({
    resource: PERMISSIONS_RESOUCE_NAME,
    metaData: {
      fields: [...PERMISSIONS_RESOUCE_FIELDS],
    },
  });

  const adminPermissions = adminPermissionsResponse?.data || [];

  const {
    handleSubmit,
    saveButtonProps,
    register,
    formState: { errors },
    refineCore: { queryResult, onFinish },
    control,
  } = useForm<AdminRoleFields, HttpError>({
    defaultValues: {
      name: '',
      description: '',
      permissions: [],
    },
    refineCoreProps: {
      resource: RESOURCE_NAME,
      metaData: {
        fields: [...RESOURCE_FIELDS],
      },
      id: parseInt(id),
      onMutationError,
      onMutationSuccess,
      action: 'edit',
      successNotification: {
        key: 'errorNotification',
        message: '',
        description: t('user-roles.fields.editRoleSuccessDescription'),
        type: 'success',
      },
      errorNotification: {
        key: 'errorNotification',
        message: t('user-roles.fields.editRoleFailMessage'),
        description: t('user-roles.fields.editRoleFailDescription'),
        type: 'error',
      },
    },
  });

  const onSubmit = (e: any) => {
    handleSubmit((fieldValues) => {
      return onFinish({ ...fieldValues, code: fieldValues?.name?.toLowerCase() });
    })(e);
  };

  const loadingForm = queryResult?.isFetching || isLoading;

  return (
    <Edit
      hideBreadcrumbs
      resource={RESOURCE_NAME}
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        ...saveButtonProps,
        type: 'submit',
        onClick: onSubmit,
      }}
      pageHeaderProps={{
        extra: <div className="space-x-2">{!hideListButton && <ListButton />}</div>,
        onBack,
      }}
    >
      {loadingForm && queryResult?.data ? (
        <LoadingSkeleton />
      ) : (
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-12">
            <div className="col-span-full">
              <div className="text-lg mb-4">Role Information</div>
              <FormControl id="name" className="mb-4">
                <FormLabel>{t('user-roles.fields.name')}</FormLabel>
                <Input
                  {...register('name', { required: true })}
                  invalid={!!errors.name}
                  placeholder={t('user-roles.fields.name')}
                  size="lg"
                />
              </FormControl>
              <FormControl id="description" className="mb-4">
                <FormLabel>{t('user-roles.fields.description')}</FormLabel>
                <Input
                  {...register('description', { required: true })}
                  invalid={!!errors.description}
                  type="email"
                  placeholder={t('user-roles.fields.description')}
                  size="lg"
                />
              </FormControl>
              <div className="text-lg mb-4">Permissions</div>
              <Controller
                name="permissions"
                defaultValue={[]}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div>
                    <FormControl id="permissions" className="mb-4 md:flex md:space-x-3">
                      <div className="w-4/12 mr-2">Administrator Access</div>
                      <Checkbox.Group
                        name="permissions"
                        value={value}
                        onChange={(newVal) => {
                          newVal.includes('super_admin') ? onChange(adminPermissions.map((p) => p.code)) : onChange([]);
                        }}
                        defaultValue={[]}
                        className="md:flex md:flex-row md:space-x-2 w-8/12"
                      >
                        {adminPermissions
                          ?.filter((i) => i.resource === '*')
                          .map((permission, index) => (
                            <Checkbox value={permission.code} key={`${permission.id}${index}`}>
                              Select all
                            </Checkbox>
                          ))}
                      </Checkbox.Group>
                    </FormControl>
                    <FormControl id="permissions" className="mb-4 md:flex md:space-x-3">
                      <div className="w-4/12 mr-2">User</div>
                      <Checkbox.Group
                        name="permissions"
                        value={value}
                        onChange={(newVal) => {
                          adminPermissions?.filter((i) => i.resource === 'user').every((p) => newVal.includes(p.code))
                            ? onChange(adminPermissions.map((p) => p.code))
                            : onChange(newVal);
                        }}
                        defaultValue={[]}
                        className="md:flex md:flex-row md:space-x-2 w-8/12"
                      >
                        {adminPermissions
                          ?.filter((i) => i.resource === 'user')
                          .map((permission, index) => (
                            <Checkbox
                              disabled={value.includes('super_admin')}
                              checked={value.includes('super_admin')}
                              value={permission.code}
                              key={`${permission.id}${index}`}
                            >
                              {permission.name}
                            </Checkbox>
                          ))}
                      </Checkbox.Group>
                    </FormControl>
                  </div>
                )}
              />
            </div>
          </div>
        </form>
      )}
    </Edit>
  );
};
