import { useTranslate, useShow } from '@pankod/refine-core';
import { ListButton, Space, Show, EditButton, DateField, Tag } from '@scalingworks/refine-vechaiui';
import { Field, LoadingSkeleton } from 'components/shared';
import { AdminUser } from 'api';
import { RESOURCE_NAME, RESOURCE_FIELDS } from '../constant';

type UserFields = Pick<AdminUser, typeof RESOURCE_FIELDS[number]> & { role: { name: string } };

export const ShowContent: React.FC<{
  id: string;
  hideListButton?: boolean;
  hideEditButton?: boolean;
  onBack?: (e?: any) => void;
}> = (props) => {
  const t = useTranslate();
  const { onBack, hideEditButton, hideListButton, id } = props;

  const { queryResult } = useShow<UserFields>({
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS, { role: ['name'] }],
    },
    id,
  });
  const user = queryResult?.data?.data;

  return (
    <>
      <Show
        hideBreadcrumbs
        pageHeaderProps={{
          extra: (
            <div className="space-x-2">
              {!hideListButton && <ListButton />}
              {!hideEditButton && <EditButton />}
            </div>
          ),
          onBack,
        }}
      >
        {queryResult?.isLoading ? (
          <LoadingSkeleton />
        ) : (
          <Space size={20} direction="vertical" style={{ width: '100%' }}>
            <div className="grid grid-cols-12">
              <div className="col-span-full md:col-span-6">
                <Field title={t('users.fields.id')} text={user?.id} />
                <Field title={t('users.fields.name')} text={user?.name} />
                <Field title={t('users.fields.email')} text={user?.email} />
                <Field
                  title={t('users.fields.status', 'Status')}
                  text={
                    <Tag color={user?.status?.includes('inactive') ? 'red' : 'primary'} className="mt-1" variant="light">
                      {user?.status}
                    </Tag>
                  }
                />
                <Field title={t('users.fields.role', 'Role')} text={user?.role?.name} />
                <Field title={t('users.fields.createdAt')} text={<DateField value={user?.createdAt} format="LLL" />} />
                <Field title={t('users.fields.updatedAt')} text={<DateField value={user?.updatedAt} format="LLL" />} />
              </div>
            </div>
          </Space>
        )}
      </Show>
    </>
  );
};
