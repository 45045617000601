import { useLogin, useTranslate } from '@pankod/refine-core';
import './style.less';

import { Trans } from 'react-i18next';
import { Layout, Card, Input, Button, FormControl, FormLabel } from '@scalingworks/refine-vechaiui';
import { useForm } from '@pankod/refine-react-hook-form';

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const ForgetPasswordPage: React.FC = () => {
  const t = useTranslate();

  const { mutate: login } = useLogin<ILoginForm>();
  const { handleSubmit, register } = useForm({
    defaultValues: {
      remember: false,
      username: 'admin',
      password: 'admin',
    },
  });

  return (
    <Layout>
      <div className="justify-center align-middle h-screen">
        <div style={{ maxWidth: '408px', margin: 'auto' }}>
          <Card
            title={
              <h3>
                <Trans
                  i18nKey="pages.login.message"
                  defaults="<0>Sign in</0> your account"
                  components={[<span key="0" className="text-primary-500" />]}
                />
              </h3>
            }
            headStyle={{ borderBottom: 0 }}
          >
            <form onSubmit={handleSubmit((values) => login(values))}>
              <FormControl id="username" className="mb-4">
                <FormLabel>{t('pages.login.email', 'Username')}</FormLabel>
                <Input
                  {...register('username', { required: true })}
                  placeholder={t('pages.login.email', 'sample@finefoods.com')}
                  size="lg"
                />
              </FormControl>

              <Button type="submit" size="lg" variant="solid">
                {t('pages.login.signin', 'Sign in')}
              </Button>
            </form>
          </Card>
        </div>
      </div>
    </Layout>
  );
};
