import React from 'react';
import routerProvider from '@pankod/refine-react-router-v6';
import { TitleProps } from '@pankod/refine-core';

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={'/logo-demo.png'}
        alt="Refine"
        style={{
          minWidth: 30,
        }}
      />
    ) : (
      <img
        src={'/logo-demo.png'}
        alt="Refine"
        className="pb-4 px-3"
        style={{
          width: '70px',
        }}
      />
    )}
  </Link>
);
