import { AuthProvider } from '@pankod/refine-core';
import { client } from './App';
import { gql } from 'graphql-request';

export const TOKEN_KEY = 'refine-auth';

export const authTokenProvider = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (token) {
    try {
      const { accessToken, expiresIn, refreshToken, refreshExpiresIn } = JSON.parse(token);

      return {
        accessToken,
        expiresIn,
        refreshToken,
        refreshExpiresIn,
      };
    } catch (e) {
      return null;
    }
  }

  return null;
};

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const query = gql`
        mutation ($username: String!, $password: String!) {
          adminLogin(data: { username: $username, password: $password }) {
            accessToken
            expiresIn
            refreshToken
            refreshExpiresIn
          }
        }
      `;

      const variables = {
        username,
        password,
      };

      const response = await client.request(query, variables);

      client.setHeaders({
        authorization: `Bearer ${response.adminLogin.accessToken}`,
      });

      localStorage.setItem(TOKEN_KEY, JSON.stringify(response.adminLogin));

      Promise.resolve();
    } catch (error: any) {
      console.log('login ERROR', error);
      return Promise.reject(error?.message)
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error?.message?.includes?.('Unauthorized')) {
      return Promise.reject('/login');
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      return Promise.resolve();
    }

    return Promise.reject({ redirectPath: '/login' });
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      return Promise.reject();
    }
    try {
      return Promise.resolve(JSON.parse(token));
    } catch (e) {
      return Promise.reject();
    }
  },
};
