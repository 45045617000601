import React, { useEffect, useRef, useState } from 'react';
import { useTranslate, IResourceComponentsProps, useExport, CrudSort, useNavigation } from '@pankod/refine-core';
import { RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { AdminRole } from 'api';
import {
  List,
  Space,
  Button,
  ExportButton,
  FormControl,
  Input,
  CreateButton,
  Drawer,
  ListTable,
} from '@scalingworks/refine-vechaiui';
import { AiOutlineClose, AiOutlineFilter, AiOutlineSearch as SearchOutlined } from 'react-icons/ai';
import { Controller, useForm } from '@pankod/refine-react-hook-form';
import { useTable, ColumnDef } from '@pankod/refine-react-table';
import { CreateContent, EditContent, ShowContent } from './containers';
import { useParams } from 'react-router-dom';

export type AdminRoleFields = Pick<AdminRole, typeof RESOURCE_FIELDS[number]>;

export const AdminRoleList: React.FC<IResourceComponentsProps> = () => {
  const { push } = useNavigation();
  const initialSorter: CrudSort[] = [{ field: 'id', order: 'asc' }];
  const initialPageSize = 10;

  const t = useTranslate();

  const { triggerExport, isLoading: exportLoading } = useExport<AdminRoleFields>({
    resourceName: RESOURCE_NAME,
    metaData: { fields: [...RESOURCE_FIELDS] },
    sorter: initialSorter,
    filters: [],
    pageSize: initialPageSize,
    maxItemCount: 1000,
    mapData: (item) => {
      return {
        id: item.id,
        name: item.name,
        description: item.description,
      };
    },
  });

  const columns = React.useMemo<ColumnDef<AdminRole>[]>(
    () => [
      {
        id: 'id',
        header: t('user-roles.fields.id'),
        accessorKey: 'id',
        enableSorting: false,
      },
      {
        id: 'name',
        header: t('user-roles.fields.name'),
        accessorKey: 'name',
      },
      {
        id: 'description',
        header: t('user-roles.fields.description'),
        accessorKey: 'description',
      },
      {
        id: 'actions',
        header: () => (
          <div className='flex w-full justify-center'>{t('table.actions')}</div>
        ),
        accessorKey: 'actions',
        enableSorting: false,
      },
    ],
    [t]
  );

  const useTableProps = useTable({
    columns,
    refineCoreProps: {
      resource: RESOURCE_NAME,
      metaData: { fields: [...RESOURCE_FIELDS] },
      initialSorter,
      initialCurrent: 1,
      initialPageSize,
    },
  });

  const [currentId, setCurrentId] = useState<string>('');

  const [action, setCurrentAction] = useState<string>();

  const drawerRef = useRef<any>(null);

  const params = useParams();

  const {
    refineCore: { setFilters, tableQueryResult },
  } = useTableProps;

  useEffect(() => {
    if (params && params.id) {
      setCurrentId(params.id);
      setCurrentAction(params.formaction);
      drawerRef.current?.toggle();
    }
  }, [params]);

  const onShowDrawer = (id?: string, type?: string) => {
    if (!id) {
      push('/admin-roles');
      return;
    }

    push(`/admin-roles/${[id, type].filter(Boolean).join('/')}`);
  };

  const Actions: React.FC = () => (
    <Space>
      <Filter
        onSearch={(value) =>
          setFilters((prev) => {
            const currentFilters = prev.filter((item: any) => item.field !== 'name');
            const newFilter: any = value ? [{ field: 'name', value, operator: 'eq' }] : [];
            return [...currentFilters, ...newFilter];
          })
        }
      />
      <ExportButton onClick={triggerExport} loading={exportLoading} />
      <CreateButton resourceName="admin-roles" onClick={() => onShowDrawer('new')} />
    </Space>
  );

  const getContent = (id?: string): React.ReactNode => {
    if (!id || id === '') {
      return null;
    }

    if (action === 'create' || id === 'new') {
      return (
        <CreateContent
          hideListButton
          onBack={() => onShowDrawer(undefined)}
          onMutationSuccess={() => setCurrentId('')}
        />
      );
    }

    if (action === 'edit') {
      return (
        <EditContent
          id={currentId}
          hideListButton
          onBack={() => onShowDrawer(undefined)}
          onMutationSuccess={() => setCurrentId('')}
        />
      );
    }

    return <ShowContent onBack={() => onShowDrawer(undefined)} id={currentId} hideListButton hideEditButton />;
  };

  return (
    <div className="space-y-4">
      <div className="">
        <List
          pageHeaderProps={{
            extra: <Actions />,
            breadcrumb: null,
          }}
        >
          <ListTable
            useTableProps={useTableProps}
            onShowClick={(id) => onShowDrawer(id, 'show')}
            onEditClick={(id) => onShowDrawer(id, 'edit')}
            isLoading={tableQueryResult.isLoading}
          />
        </List>
      </div>

      <Drawer
        ref={drawerRef}
        drawerContent={getContent(currentId)}
        onHide={() => onShowDrawer(undefined)}
        onShow={() => null}
      />
    </div>
  );
};

const Filter: React.FC<{ onSearch: (value: any) => any }> = (props) => {
  const t = useTranslate();
  const { handleSubmit, control } = useForm();

  const onHandleSubmit = (values: any) => {
    console.log({ values });
    props.onSearch(values.q);
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <div className="flex flex-row items-center">
        <div className="flex-grow">
          <FormControl id="q">
            <Controller
              control={control}
              name="q"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  placeholder={t('user.filter.search.placeholder')}
                  size="lg"
                  addonBefore={<SearchOutlined />}
                  rightElement={field.value && <AiOutlineClose onClick={() => field.onChange('')} />}
                  {...field}
                />
              )}
            />
          </FormControl>
        </div>

        <div className="ml-2">
          <FormControl>
            <Button
              leftIcon={<AiOutlineFilter className="mr-2" />}
              style={{ width: '100%' }}
              type="submit"
              color="primary"
            >
              {t('user.filter.submit')}
            </Button>
          </FormControl>
        </div>
      </div>
    </form>
  );
};
