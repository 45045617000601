import { useTranslate, HttpError, CreateResponse, UpdateResponse, useList } from '@pankod/refine-core';
import { FieldValues, useForm } from '@pankod/refine-react-hook-form';
import { Create, FormControl, FormLabel, Input, Radio, ListButton, Select } from '@scalingworks/refine-vechaiui';
import { RESOURCE_FIELDS, RESOURCE_NAME, ROLES_RESOURCE_FIELDS, ROLES_RESOURCE_NAME } from '../constant';
import { AdminUser, AdminRole } from '../../../api';
import { LoadingSkeleton } from '../../../components/shared';

type UserFields = Pick<AdminUser, typeof RESOURCE_FIELDS[number]>;
type UserRoleFields = Pick<AdminRole, typeof ROLES_RESOURCE_FIELDS[number]>;
type OnMutationSuccess = (
  data: CreateResponse<UserFields> | UpdateResponse<UserFields>,
  variables: FieldValues,
  context: any
) => void | undefined;

type OnMutationError = ((error: HttpError, variables: FieldValues, context: any) => void) | undefined;

export const UserCreate: React.FC<{
  hideListButton?: boolean;
  onMutationSuccess?: OnMutationSuccess;
  onMutationError?: OnMutationError;
  onBack?: (e?: any) => void;
}> = (props) => {
  const t = useTranslate();
  const { onBack, onMutationSuccess, onMutationError } = props;

  const { data, isLoading } = useList<UserRoleFields>({
    resource: ROLES_RESOURCE_NAME,
    metaData: {
      fields: [...ROLES_RESOURCE_FIELDS],
    },
  });

  const userRoles = data?.data || [];

  const {
    handleSubmit,
    saveButtonProps,
    register,
    formState: { errors },
    refineCore: { queryResult, onFinish },
  } = useForm<UserFields, HttpError>({
    refineCoreProps: {
      resource: RESOURCE_NAME,
      metaData: {
        fields: [...RESOURCE_FIELDS],
      },
      onMutationSuccess,
      onMutationError,
    },
  });

  const loadingForm = isLoading;

  const onSubmit = (e: any) => {
    handleSubmit((fieldValues) => {
      console.log({ fieldValues });
      const newValues: any = {
        ...fieldValues,
        role: {
          connect: {
            code: fieldValues.role,
          },
        },
      };
      return onFinish(newValues);
    })(e);
  };

  return (
    <Create
      hideBreadcrumbs
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        ...saveButtonProps,
        type: 'submit',
        onClick: onSubmit,
      }}
      pageHeaderProps={{
        extra: !props?.hideListButton && <ListButton />,
        onBack,
      }}
    >
      {loadingForm ? (
        <LoadingSkeleton />
      ) : (
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-12">
            <div className="col-span-full">
              <FormControl id="username" className="mb-4">
                <FormLabel>{t('users.fields.username', 'Username')}</FormLabel>
                <Input
                  {...register('username', { required: true })}
                  invalid={!!errors.username}
                  placeholder={t('users.fields.username', 'Username')}
                  size="lg"
                />
              </FormControl>
              <FormControl id="name" className="mb-4">
                <FormLabel>{t('users.fields.name')}</FormLabel>
                <Input
                  {...register('name', { required: true })}
                  invalid={!!errors.name}
                  placeholder={t('users.fields.name')}
                  size="lg"
                />
              </FormControl>
              <FormControl id="email" className="mb-4">
                <FormLabel>{t('users.fields.email')}</FormLabel>
                <Input
                  {...register('email', { required: true })}
                  invalid={!!errors.email}
                  type="email"
                  placeholder={t('users.fields.email', 'LastName')}
                  size="lg"
                />
              </FormControl>
              <FormControl id="password" className="mb-4">
                <FormLabel>{t('users.fields.password')}</FormLabel>
                <Input
                  {...register('password', { required: true })}
                  invalid={!!errors.password}
                  type="password"
                  placeholder="●●●●●●●●"
                  size="lg"
                />
              </FormControl>
              <FormControl className="mb-4">
                <FormLabel>{t('users.fields.active.label')}</FormLabel>
                <Radio.Group name="status" defaultValue="active" className="space-x-4" inline>
                  <Radio {...register('status')} value="active">
                    {t('users.fields.activelabel', 'Active')}
                  </Radio>
                  <Radio {...register('status')} value="inactive">
                    {t('users.fields.inactive', 'Inactive')}
                  </Radio>
                </Radio.Group>
              </FormControl>
              <FormControl className="mb-4">
                <FormLabel>{t('users.fields.roles', 'Roles')}</FormLabel>
                <Select {...register('role')} name="role" defaultValue={data?.data[0]?.code} className="space-x-4">
                  {userRoles.map((role) => (
                    <option key={role.id} value={role.code}>
                      {role.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </form>
      )}
    </Create>
  );
};
