import React, { useEffect, useState } from 'react';
import { useLogout, useTitle, useNavigation, useMenu } from '@pankod/refine-core';
import { AiOutlineLogout as LogoutOutlined, AiOutlineUser as UserOutlined } from 'react-icons/ai';
import { Sidebar } from '@scalingworks/refine-vechaiui';
import { getSidebarState, setSidebarState } from 'libs/sidebar-storage';

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(() => getSidebarState().collapsed);
  const { mutate: logout } = useLogout();
  const Title = useTitle();
  const { menuItems, selectedKey } = useMenu();
  const { push } = useNavigation();

  useEffect(() => {
    setSidebarState({ collapsed });
  }, [collapsed]);

  const allMenus = menuItems.map(({ icon, label, route, children }) => {
    const isChildSelected = children?.some((child) => child.route === selectedKey);
    const isSelected = route === selectedKey || isChildSelected;
    let MenuIcon = icon;
    if (route?.includes('users')) {
      MenuIcon = <UserOutlined />;
    }
    return {
      title: label,
      icon: MenuIcon,
      isSelected,
      isCollapsed: collapsed,
      isDetailsOpen: isSelected,
      nestedMenus: !!children.length
        ? children.map((item) => ({
            icon: item.icon,
            link: item.route,
            title: item.label,
            isSelected: item.route === selectedKey,
          }))
        : undefined,
      ...(children?.length && collapsed
        ? {
            onClick: (e: any) => {
              e.preventDefault();
              setCollapsed(false);
            },
          }
        : {}),
      ...(!children || !children?.length
        ? {
            onClick: (e: any) => {
              e.preventDefault();
              push(route as string);
            },
          }
        : {}),
    };
  });

  return (
    <Sidebar
      title={Title && <Title collapsed={collapsed} />}
      isCollapsed={collapsed}
      onCollapsed={() => setCollapsed((prev) => !prev)}
      footerMenu={{
        onClick: (e: any) => {
          e.preventDefault();
          logout();
        },
        isCollapsed: collapsed,
        icon: <LogoutOutlined />,
        title: 'Logout',
      }}
      menus={[...allMenus]}
    />
  );
};
